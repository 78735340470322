import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { O8_CUSTOM_HEADERS } from 'common.interfaces';
import { TRACKER_SERVICE, TrackingService } from './tracker.interface';
import { map, switchMap } from 'rxjs';

@Injectable()
export class TrackerInterceptor implements HttpInterceptor {
  constructor(@Inject(TRACKER_SERVICE) private trackerService: TrackingService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const requestId = this.trackerService.getRequestId();
    const sessionId = this.trackerService.getSessionId();
    if (req.url === '/assets/config/config.json') {
      return next.handle(req);
    }
    return this.trackerService.getImpersonationToken().pipe(
      map((impersonationToken) => {
        return req.clone({
          headers: req.headers
            .set(O8_CUSTOM_HEADERS.TRACE_ID, requestId)
            .set(O8_CUSTOM_HEADERS.SESSION_ID, sessionId)
            .set(O8_CUSTOM_HEADERS.IMPERSONATION, impersonationToken),
        });
      }),
      switchMap((trackedRequest) => {
        return next.handle(trackedRequest);
      }),
    );
  }
}
