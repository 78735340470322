import { Observable, of } from 'rxjs';
import { TrackingService } from './tracker.interface';

export class TrackerServerService implements TrackingService {
  sessionId!: string; /* Initialized in the lifetime of the app*/

  constructor() {}
  track(event: string, data: object): void {}

  getSessionId(): string {
    return 'server-session';
  }

  getImpersonationToken(): Observable<string> {
    return of('');
  }

  getRequestId(): string {
    return 'server-request';
  }
}
