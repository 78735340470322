import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../../shared-providers';
import { getRandomUuid } from './uuid.utils';

@Injectable({
  providedIn: 'root',
})
export class UuidService {
  constructor(@Inject(WINDOW) private window: Window) {}

  getRandomUUID() {
    return getRandomUuid(this.window);
  }
}
